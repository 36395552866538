import Navbar from 'organisms/Navbar';
import NavigationSidebar from 'organisms/NavigationSidebar';
import React, { useState, useCallback } from 'react';

const Layout = ({ children }) => {
  const [showNavMenu, setShowNavMenu] = useState(false);

  const onToggleNav = useCallback(() => {
    showNavMenu ? setShowNavMenu(false) : setShowNavMenu(true);
  }, [showNavMenu]);

  return (
    <div className="h-screen overflow-hidden">
      <Navbar ToggleNav={onToggleNav} />
      <div className="h-[calc(100%-50px)] 2xl:h-[calc(100%-70px)] overflow-x-hidden overflow-y-auto scrollbar">
        <div className="flex w-full max-w-[1700px] mx-auto relative h-fit">
          {showNavMenu && (
            <div className="show-on-mobile fixed" style={{ zIndex: 0 }}>
              <NavigationSidebar />
            </div>
          )}
          <div className="hide-on-mobile" style={{ zIndex: 0 }}>
            <NavigationSidebar />
          </div>
          <div className="h-fit flex-1 w-[78%]">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
